export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  gap: 0;

  @media ${({ theme }) => theme.media.desktop} {
    gap: 30px;
  }
`;

import styled from 'styled-components';

export const Main = styled.main<{ paddingTop: boolean }>`
  width: ${({ theme }) => theme.contentMaxWidth};
  max-width: 100vw;
  margin-bottom: 30px;
  padding-top: ${({ paddingTop }) => (paddingTop ? '59px' : '0')};
`;

export const Aside = styled.aside`
  width: 400px;
  margin: 59px 0;
  display: none;

  > div {
    margin-bottom: 30px;
  }

  @media ${({ theme }) => theme.media.desktop} {
    display: block;
  }
`;
