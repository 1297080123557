import Link from 'next/link';
import type { ReactElement } from 'react';
import { Fragment } from 'react';

import { Breadcrumb, BreadcrumbsWrapper } from './Breadcrumbs.styled';
import type { BreadcrumbsProps } from './Breadcrumbs.types';

export const Breadcrumbs = ({
  breadcrumbs,
}: BreadcrumbsProps): ReactElement => (
  <BreadcrumbsWrapper>
    {breadcrumbs?.map((breadcrumb, idx, array) => {
      if (idx === array.length - 1) {
        return (
          <Fragment key={breadcrumb.title}>
            <Breadcrumb>
              {breadcrumb.title}
              <div />
            </Breadcrumb>
          </Fragment>
        );
      }

      return (
        <Link href={breadcrumb.link} key={breadcrumb.title}>
          <Breadcrumb>
            {breadcrumb.title}
            <div />
          </Breadcrumb>
        </Link>
      );
    })}
  </BreadcrumbsWrapper>
);
