export interface SeoHeadHookProps {
  blogSchema: ArticleSchemaProps;
  breadcrumbSchema: BreadcrumbListSchemaProps;
  metaDescription: string;
  title: string;
}

export enum SCHEMA_TYPE {
  BLOG = 'Blog',
  BLOG_POSTING = 'BlogPosting',
  BREADCRUMB_LIST = 'BreadcrumbList',
}

export interface SeoHeadHook {
  createBlogPostSchema: (props: ArticleSchemaProps) => BlogPostingSchema;
  createBreadcrumbListSchema: (
    props: BreadcrumbListSchemaProps,
  ) => BreadcrumbListSchema;
  createGeneralSchema: () => BlogSchema;
  currentUrl: string;
  description: string;
  robots: string;
}

export interface SeoHeadProps {
  blogSchema?: ArticleSchemaProps;
  breadcrumbSchema?: BreadcrumbListSchemaProps;
  image?: string;
  metaDescription?: string;
  noIndex?: boolean;
  title: string;
}

export interface ArticleSchemaProps {
  dateCreated: string;
  dateModified: string;
  datePublished: string;
  description: string;
  keywords: string[];
  mainImage: string;
  title: string;
  url: string;
}

export interface BreadcrumbListSchemaProps {
  breadcrumbElements?: {
    breadcrumbUrl?: string;
    title: string;
  }[];
}

export interface BaseSchema {
  '@context': 'https://schema.org';
  '@type': SCHEMA_TYPE;
}

export interface BlogPostingSchema extends BaseSchema {
  author: {
    '@type': string;
    name: string;
    url: string;
  }[];
  dateCreated;
  dateModified: string;
  datePublished: string;
  description: string;
  headline: string;
  image: string[];
  inLanguage: string;
  keywords: string[];
  name: string;
  url: string;
}

export interface BreadcrumbListSchema extends BaseSchema {
  itemListElement: {
    '@type': 'ListItem';
    item?: string;
    name: string;
    position: number;
  }[];
}

export interface BlogSchema extends BaseSchema {
  '@id': string;
  about: string;
  alternateName: string;
  description: string;
  inLanguage: string;
  isPartOf: {
    '@id': string;
  };
  name: string;
  potentialAction: {
    '@type': 'SearchAction';
    'query-input': 'required name=search_term_string';
    target: {
      '@type': 'EntryPoint';
      urlTemplate: 'https://foxparadox.pl/szukaj/?q={search_term_string}';
    };
  };
  url: string;
}
