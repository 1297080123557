import Image from '@atoms/Image';
import Tag from '@atoms/Tag';
import Link from 'next/link';
import type { ReactElement } from 'react';

import {
  ImageWrapper,
  MiniCardWrapper,
  TextWrapper,
  Title,
} from './MiniCard.styled';
import type { MiniCardProps } from './MiniCard.types';

export const MiniCard = ({
  image,
  title,
  tag,
  link,
}: MiniCardProps): ReactElement => (
  <Link href={link}>
    <MiniCardWrapper>
      <ImageWrapper>
        <Image
          alt={image.alt}
          src={image.src}
          width={100}
          height={100}
          quality={40}
        />
      </ImageWrapper>
      <TextWrapper>
        <Tag link={tag.link} title={tag.title} />
        <Title>{title}</Title>
      </TextWrapper>
    </MiniCardWrapper>
  </Link>
);
