import MiniCard from '@molecules/MiniCard';
import type { ReactElement } from 'react';

import { CardWrapper, SingleArticleWrapper } from './LatestArticles.styled';
import type { LatestArticlesProps } from './LatestArticles.types';

export const LatestArticles = ({
  articles,
}: LatestArticlesProps): ReactElement => (
  <CardWrapper>
    <h2>✏️ Ostatnie wpisy</h2>
    {articles?.map(({ title, image, link, tag }) => (
      <SingleArticleWrapper key={title}>
        <MiniCard image={image} link={link} tag={tag} title={title} />
      </SingleArticleWrapper>
    ))}
  </CardWrapper>
);
