import styled from 'styled-components';

export const MiniCardWrapper = styled.div`
  height: 95px;
  display: grid;
  grid-template-columns: 95px 1fr;

  :hover {
    p {
      color: ${({ theme }) => theme.palette.primary};
    }
  }

  p {
    color: ${({ theme }) => theme.palette.dark};
  }

  :hover {
    img {
      transform: scale(1.1);
    }
  }

  img {
    border-radius: 6px;
    object-fit: cover;
    transition: all 0.3s ease;
    width: 95px;
    height: 95px;
  }

  > div {
    height: 95px;
  }
`;

export const TextWrapper = styled.div`
  margin-left: 10px;
`;

export const Title = styled.p`
  font-family: Roboto, Helvetica, sans-serif;
  font-weight: 500;
  margin: 8px 0 16px 0;
  line-height: 20px;
`;

export const ImageWrapper = styled.div`
  overflow: hidden;
  border-radius: 6px;
`;
