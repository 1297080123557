import Image from '@atoms/Image';
import Tag from '@atoms/Tag';
import Link from 'next/link';
import type { ReactElement } from 'react';

import { TagWrapper, Wrapper } from './SliderCard.styled';
import type { SliderCardProps } from './SliderCard.types';

export const SliderCard = ({
  image,
  title,
  date,
  link,
  tag,
}: SliderCardProps): ReactElement => (
  <Link href={link}>
    <Wrapper>
      <Image
        src={image.src}
        alt={image.alt}
        width={300}
        height={300}
        loading="eager"
      />
      <TagWrapper>
        <Tag title={tag} />
      </TagWrapper>
      <h3>{title}</h3>
      <p>{date}</p>
    </Wrapper>
  </Link>
);
