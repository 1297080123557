import styled from 'styled-components';

import { cardBackround } from '@/styles/shared';

export const Wrapper = styled.div`
  ${cardBackround};
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0;
  padding: 30px;

  @media ${({ theme }) => theme.media.landscapeMobile} {
    flex-direction: row;
    padding: 30px;
    gap: 25px;
  }

  @media ${({ theme }) => theme.media.desktop} {
    flex-direction: column;
    gap: 0;
  }

  h2 {
    margin: 24px 0 0 0;
    width: 100%;
    font-size: 20px;

    @media ${({ theme }) => theme.media.tablet} {
      font-size: 24px;
    }
  }

  img {
    border-radius: 100%;
    height: 200px;
    width: 200px;
    object-fit: cover;
    aspect-ratio: 1/1;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  min-width: 200px;
  display: flex;
  justify-content: center;
`;

export const TextWrapper = styled.div``;
