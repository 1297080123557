import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    margin: 6px 0 0 0;
    width: 100%;
    font-size: 18px;
    line-height: 22px;
    font-family: Montserrat, Helvetica, sans-serif;
    font-weight: 700;
    color: ${({ theme }) => theme.palette.dark};

    @media ${({ theme }) => theme.media.tablet} {
      font-size: 18px;
    }
  }

  img {
    height: 270px;
    width: 100%;
    object-fit: cover;
    border-radius: 6px;
  }

  p {
    color: ${({ theme }) => theme.palette.dark};
    text-align: left;
    font-size: 14px;
    margin-top: 2px;
  }
`;

export const TagWrapper = styled.div`
  margin-top: 12px;
`;
