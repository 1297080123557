import AboutMeCard from '@molecules/AboutMeCard';
import Breadcrumbs from '@molecules/Breadcrumbs';
import LatestArticles from '@organisms/LatestArticles';
import Slider from '@organisms/Slider';
import dynamic from 'next/dynamic';
import type { ReactElement } from 'react';

const PaintingsSlider = dynamic(() => import('@organisms/PaintingsSlider'));
const InstagramPreview = dynamic(() => import('@organisms/InstagramPreview'));

import { useState } from 'react';
import { InView } from 'react-intersection-observer';

import { Aside, Main, Wrapper } from './ColumnLayout.styled';
import type { ColumnLayoutType } from './ColumnLayout.types';

export const ColumnLayout = ({
  children,
  instagramFeed,
  slides,
  latestArticles,
  breadcrumbs,
  hideBreadcrumbs,
  paintingsSlider,
}: ColumnLayoutType): ReactElement => {
  const [isAsideVisible, setIsAsideVisible] = useState<boolean>(false);

  return (
    <Wrapper>
      <Main paddingTop={hideBreadcrumbs}>
        {!hideBreadcrumbs && <Breadcrumbs {...breadcrumbs} />}
        {children}
      </Main>
      <InView onChange={setIsAsideVisible}>
        <Aside>
          {isAsideVisible && (
            <>
              <AboutMeCard />
              <PaintingsSlider {...paintingsSlider} />
              <Slider {...slides} />
              <InstagramPreview {...instagramFeed} />
              <LatestArticles {...latestArticles} />
            </>
          )}
        </Aside>
      </InView>
    </Wrapper>
  );
};
