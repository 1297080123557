import SliderCard from '@molecules/SliderCard';
import { ArrowLeftCircleFill } from '@styled-icons/bootstrap/ArrowLeftCircleFill';
import type { ReactElement } from 'react';
import { useState } from 'react';
import ReactSimplyCarousel from 'react-simply-carousel';

import {
  Dot,
  DotsWrapper,
  OverlayLink,
  SlideWrapper,
  Wrapper,
} from './Slider.styled';
import type { SliderProps } from './Slider.types';

export const Slider = ({ slides }: SliderProps): ReactElement => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  if (!slides || slides.length === 0) return null;

  return (
    <Wrapper>
      <h2>🤓 Przeczytaj</h2>
      <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={1}
        itemsToScroll={1}
        speed={300}
        easing="ease-in-out"
        backwardBtnProps={{
          className: 'backButton',
          children: (
            <>
              <ArrowLeftCircleFill />
              <span>Następny slajd</span>
            </>
          ),
        }}
        forwardBtnProps={{
          className: 'forwardButton',
          children: (
            <>
              <ArrowLeftCircleFill />
              <span>Następny slajd</span>
            </>
          ),
        }}
      >
        {slides.map((slide, index) => (
          <SlideWrapper key={`${slide.title}-${index}`}>
            <OverlayLink
              as="div"
              onClick={() => (window.location.href = slide.link)}
            />
            <SliderCard {...slide} />
          </SlideWrapper>
        ))}
      </ReactSimplyCarousel>
      {slides.length > 1 && (
        <DotsWrapper>
          {slides.map((dot, index) => (
            <Dot
              key={`${dot.title}-${index}`}
              onClick={() => setActiveSlideIndex(index)}
              isActive={activeSlideIndex === index}
            />
          ))}
        </DotsWrapper>
      )}
    </Wrapper>
  );
};
