import Link from 'next/link';
import type { ReactElement } from 'react';

import { TagWrapper } from './Tag.styled';
import type { TagProps } from './Tag.types';

export const Tag = ({ title, link }: TagProps): ReactElement => {
  if (link)
    return (
      <Link href={link}>
        <TagWrapper>{title}</TagWrapper>
      </Link>
    );

  return <TagWrapper>{title}</TagWrapper>;
};
