import Image from '@atoms/Image';
import type { ReactElement } from 'react';

import { ImageWrapper, TextWrapper, Wrapper } from './AboutMeCard.styled';

export const AboutMeCard = (): ReactElement => (
  <Wrapper>
    <ImageWrapper>
      <Image
        src="/static/images/about-me.jpg"
        alt="Moje zdjęcie."
        width={200}
        height={200}
      />
    </ImageWrapper>
    <TextWrapper>
      <h2>Cześć! 👋 Jestem Magda</h2>
      <p>
        Uwielbiam włoską kuchnię, hiszpański język i wiedeńską architekturę.
        Polskie góry i ruskie pierogi. Zwiedzam europejskie zakątki korzystając
        z tanich lotów i wyszukując ciekawe do odwiedzenia miejsca. A w
        przerwach od podróży piszę o życiu, niezmiennie przy kubku kawy.
        Wszystko opisuję tutaj, częstuj się!
      </p>
    </TextWrapper>
  </Wrapper>
);
