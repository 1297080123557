import styled from 'styled-components';

export const BreadcrumbsWrapper = styled.ul`
  margin: 15px 10px;
  font-family: Roboto, Helvetica, sans-serif;

  @media ${({ theme }) => theme.media.tablet} {
    margin: 20px 0;
  }

  a:first-child {
    padding-left: 0;

    div {
      display: none;
    }
  }

  a {
    color: ${({ theme }) => theme.palette.dark};

    :hover {
      color: ${({ theme }) => theme.palette.primary};
    }
  }
`;

export const Breadcrumb = styled.li`
  padding: 0 8px;
  font-size: 13px;
  display: inline-block;
  position: relative;

  div {
    position: absolute;
    content: '';
    border: 1px solid ${({ theme }) => theme.palette.dark};
    border-top: none;
    border-left: none;
    width: 4px;
    height: 4px;
    left: -4px;
    top: 50%;
    transform: rotate(-45deg) translate(50%);
  }
`;
