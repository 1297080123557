import type {
  BlogPostingSchema,
  BlogSchema,
  BreadcrumbListSchema,
  SeoHeadHook,
  SeoHeadHookProps,
} from './SeoHead.types';
import { SCHEMA_TYPE } from './SeoHead.types';

export const useSeoHead = ({
  blogSchema,
  breadcrumbSchema,
  metaDescription,
  title,
}: SeoHeadHookProps): SeoHeadHook => {
  const currentUrl = typeof window !== 'undefined' ? window.location.href : '';
  const description = metaDescription
    ? `${metaDescription}`
    : `${title} | Fox Paradox`;
  const robots =
    process.env.ENVIRONMENT === 'production'
      ? 'index, follow'
      : 'noindex, nofollow';

  const createBlogPostSchema = (): BlogPostingSchema => ({
    '@type': SCHEMA_TYPE.BLOG_POSTING,
    '@context': 'https://schema.org',
    author: [
      {
        '@type': 'Person',
        name: 'Fox Paradox',
        url: 'https://foxparadox.pl/o-mnie/',
      },
    ],
    dateModified: blogSchema.dateModified,
    datePublished: blogSchema.datePublished,
    headline: title,
    image: [blogSchema.mainImage],
    inLanguage: 'pl-PL',
    dateCreated: blogSchema.dateCreated,
    keywords: blogSchema.keywords,
    url: blogSchema.url,
    name: title,
    description,
  });

  const createBreadcrumbListSchema = (): BreadcrumbListSchema => ({
    '@context': 'https://schema.org',
    '@type': SCHEMA_TYPE.BREADCRUMB_LIST,
    itemListElement: breadcrumbSchema.breadcrumbElements?.map(
      (element, idx, arr) => ({
        '@type': 'ListItem',
        position: idx + 1,
        name: element.title,
        ...(idx + 1 !== arr.length && {
          item: element.breadcrumbUrl,
        }),
      }),
    ),
  });

  const createGeneralSchema = (): BlogSchema => ({
    '@type': SCHEMA_TYPE.BLOG,
    '@context': 'https://schema.org',
    '@id': 'https://foxparadox.pl/#website',
    url: 'https://foxparadox.pl/',
    name: 'Fox Paradox',
    description:
      'Blog o sposobach na tanie latanie, wartych uwagi miejscach, dobrym jedzeniu, sztuce i sposobach na motywację z rana! Wszystko w jednym miejscu!',
    potentialAction: {
      '@type': 'SearchAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: 'https://foxparadox.pl/szukaj/?q={search_term_string}',
      },
      'query-input': 'required name=search_term_string',
    },
    inLanguage: 'pl-PL',
    isPartOf: {
      '@id': 'https://foxparadox.pl/#website',
    },
    about:
      'Treści o tematyce podróżniczej. Wskazówki na temat kupowania tanich lotów, planowania wycieczek, wypożyczania samochodu za granicą.',
    alternateName: 'FoxParadox',
  });

  return {
    createBreadcrumbListSchema,
    createBlogPostSchema,
    createGeneralSchema,
    currentUrl,
    description: metaDescription,
    robots,
  };
};
