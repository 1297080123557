import styled from 'styled-components';

import { gradient } from '@/styles/shared';

export const TagWrapper = styled.div`
  background: ${gradient};
  border-radius: 40px;
  min-width: 80px;
  height: 23px;
  width: max-content;
  color: white;
  font-size: 12px;
  display: grid;
  place-content: center;
  letter-spacing: 0.5px;
  padding: 0 20px;
  line-height: 23px;
`;
